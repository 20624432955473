<template>
  <div v-loading="loading">
    <CCard>
      <CCardHeader>
        <strong>{{ $t('cloud.title.lpr_sync') }}</strong>
      </CCardHeader>
      <CCardBody>
        <el-form label-width="25%">
          <el-form-item :label="$t('cloud.title.name')">
            <el-input v-model="record.name" clearable style="width:240px;" placeholder="Device Sync" />
          </el-form-item>
          <el-form-item :label="$t('cloud.title.all_device')" class="mb-1">
            <el-checkbox v-model="record.all_bridges" disabled />
          </el-form-item>
          <el-form-item :label="$t('cloud.title.device')">
            <el-select v-model="record.bridges" :disabled="record.all_bridges" multiple style="width:320px;">
              <el-option v-for="bridge in bridge_list" :label="bridge.name" :value="bridge.id" :key="`bridge-opts-${bridge.id}`" />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('cloud.title.all_lp')" class="mb-1">
            <el-checkbox v-model="record.all_groups" disabled />
          </el-form-item>
          <el-form-item :label="$t('cloud.title.group')">
            <el-select v-model="record.groups" :disabled="record.all_groups" multiple style="width:320px;">
              <el-option v-for="group in group_list" :label="group.name" :value="group.id" :key="`group-opts-${group.id}`" />
            </el-select>
          </el-form-item>
        </el-form>
      </CCardBody>
      <CCardFooter>
        <CRow class="justify-content-end mx-4">
          <CButton class="mx-1" @click="onCancel" color="secondary">{{$t('button.cancel')}}</CButton>
          <CButton class="mx-1" @click="onSubmit" color="primary">{{$t('button.submit')}}</CButton>
        </CRow>
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  props: {
    initData: {type: Object}
  },
  data() {
    return {
      loading: false,
      record: {
        id: null,
        name: "Device Sync",
        all_bridges: true,
        all_groups: true,
        bridges: [],
        groups: []
      },
      bridge_list: [],
      group_list: []
    }
  },
  computed: {
    ...mapState([
      'capability'
    ])
  },
  mounted() {
    this.fetchInitData();
  },
  methods: {
    fetchInitData() {
      if (this.initData) {
        this.record = this.$utility.copy(this.initData);
      }
      this.getGroups();
      this.getBridges();
    },
    getGroups() {
      axios.get(`/api/lpr/jp/group/?ipp=10000&page=1`)
        .then(result => {
          this.group_list = JSON.parse(JSON.stringify(result.data.list));
        })
        .catch(error => {
          console.error(error);
        });
    },
    getBridges() {
      axios.get(`/api/bridge/?ipp=10000&page=1`)
        .then(result => {
          this.bridge_list = JSON.parse(JSON.stringify(result.data.list));
        })
        .catch(error => {
          console.error(error);
        });
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      if (!this.record.name) {
        this.$notify.warning({
          title: this.$t('msgbox.confirm.title'),
          message: this.$t('msgbox.validate.name'),
          offset: 30
        });
        return;
      }
      this.$confirm(this.$t('msgbox.confirm.content'), this.$t('msgbox.confirm.title'), {
        confirmButtonText: this.$t('button.ok'),
        cancelButtonText: this.$t('button.cancel'),
        type: 'warning'
      }).then(() => {
        this.$emit('payload', this.record);
      })
    }
  }
}
</script>